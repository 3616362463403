import * as React from 'react'
import {Component, ComponentType, SVGProps} from 'react'
import {
  Badge,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
  withWidth
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import MenuIcon from '@material-ui/icons/Menu'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {isWidthUp, WithWidth} from '@material-ui/core/withWidth'
import {UserProfile} from '../model/UserProfile'
import {PlaylistMode} from '../model/PlaylistMode'
import {ControllerConnectionState} from '../model/controller/ControllerConnectionState'
import {controllersAreSupported} from '../services/ControllerService'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay'
import BluetoothIcon from '@material-ui/icons/Bluetooth'
import BluetoothConnectedIcon from '@material-ui/icons/BluetoothConnected'
import BluetoothSearchingIcon from '@material-ui/icons/BluetoothSearching'
import LocationSearchingIcon from '@material-ui/icons/LocationSearching'
import ClearIcon from '@material-ui/icons/Clear'
import FilterListIcon from '@material-ui/icons/FilterList'
import {SheetType} from '../model/SheetType'
import {getTopViewLabel, TopView} from '../model/TopView'

export interface DataProps {
  Logo: ComponentType<SVGProps<SVGSVGElement>>
  isOnline: boolean
  persistentDrawerIsOpen: boolean
  sheetIsReadOnly: boolean
  isInEditMode: boolean
  numOfflineSounds: number
  numSounds: number
  isDownloading: boolean
  downloadProgress?: number
  currentSoundMarkerIndex?: number
  sheetTitle?: string
  isInstallable: boolean
  signedInUser?: UserProfile
  playlistMode: PlaylistMode
  controllerConnectionState: ControllerConnectionState
  teaserLengthInSeconds: number
  filterIsActive: boolean
  topView: TopView
  sheetType?: SheetType
  editButtonEnabled: boolean
  offlineButtonEnabled: boolean
  hasActiveMarker: boolean
}

export interface DispatchProps {
  enterEditMode: () => void
  leaveEditMode: () => void
  makeSoundsAvailableOffline: () => void
  cancelDownload: () => void
  purgeOfflineSounds: () => void
  removeVisibleOfflineSounds: () => void
  togglePersistentDrawer: () => void
  showSwipeableDrawer: () => void
  openFilterPanel: () => void
  showInstallPrompt: () => void
  signOut: () => void
  connectController: () => void
  disconnectController: () => void
  setPlaylistMode: (playlistMode: PlaylistMode) => void
  switchToNextLogo: () => void
  goToActiveSoundMarker: () => void
  refresh: () => void
}

export interface AdditionalDispatchProps {
  print: () => void
  goFullScreen: () => void
}

interface State {
  anchorEl?: HTMLButtonElement
}

const styles = (theme: Theme) => createStyles({
  root: {
    userSelect: 'none'
  },
  menuButton: {
    marginLeft: '-12px',
    marginRight: 0,
  },
  grow: {
    flexGrow: 1,
  },
  menuToolbar: {
    textAlign: 'center',
    '&:focus': {
      outline: 'none'
    },
  },
  badge: {
    transform: 'none',
    transition: 'none',
    height: '15px',
    minWidth: '15px',
    fontSize: '0.6rem',
    padding: 0,
    top: '4px',
    right: '5px',
  },
  invisible: {
    transform: 'scale(0)'
  },
  offlineButtonWrapper: {
    position: 'relative',
  },
  offlineProgress: {
    position: 'absolute',
    top: '4px',
    left: '4px',
    // zIndex: 1,
  },
  offlineCancelButton: {
    zIndex: 1
  },
  logo: {
    height: '50px',
    marginRight: 2 * theme.spacing(1),
  },
})

function getPlaylistModeTooltip(playlistMode: PlaylistMode, teaserLengthInSeconds: number) {
  switch (playlistMode) {
    case PlaylistMode.Off:
      return 'Plays active marker only and stops'
    case PlaylistMode.OnNormal:
      return 'Proceeds to next marker at end of section or file'
    case PlaylistMode.OnTeaser:
      return `Proceeds to next marker at end of section or file or after ${teaserLengthInSeconds} seconds`
  }
}

function getNextPlaylistMode(currentPlaylistMode: PlaylistMode) {
  switch (currentPlaylistMode) {
    case PlaylistMode.Off:
      return PlaylistMode.OnNormal
    case PlaylistMode.OnNormal:
      return PlaylistMode.OnTeaser
    default:
      return PlaylistMode.Off
  }
}

class RawCustomToolbar extends Component<DataProps & DispatchProps & AdditionalDispatchProps & WithStyles<typeof styles> & WithWidth> {
  public state: State = {
    anchorEl: undefined
  }

  public render() {
    const classes = this.props.classes
    const menuIsOpen = this.state.anchorEl !== undefined
    const isDesktop = isWidthUp('sm', this.props.width)
    return (
      <Toolbar className={classes.root}>
        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
                    onClick={isDesktop ? this.props.togglePersistentDrawer : this.props.showSwipeableDrawer}>
          {isDesktop && this.props.persistentDrawerIsOpen ? <MenuOpenIcon/> : <MenuIcon/>}
        </IconButton>
        <Hidden xsDown={true} implementation="css">
          <Grid container={true} alignItems="center" onClick={this.props.switchToNextLogo} wrap="nowrap">
            {/*<this.props.Logo className={classes.logo}/>*/}
            <Typography variant="h5" color="inherit">
              {this.title}
            </Typography>
          </Grid>
        </Hidden>

        <div className={classes.grow}/>

        <>
          {this.props.editButtonEnabled && this.EditSheetButton()}
          {this.props.offlineButtonEnabled && this.MakeAvailableOfflineButton()}
          {this.OpenFilterButton()}
          {this.PlaylistModeButton()}
          {this.GoToActiveSoundMarkerButton()}
          {isDesktop && this.GoFullScreenButton()}
          {isDesktop && controllersAreSupported() && this.ControllerButton()}
        </>

        <IconButton
          aria-owns={menuIsOpen ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.openMenu}
          color="inherit"
        >
          <MoreVertIcon/>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={menuIsOpen}
          onClose={this.closeMenu}
        >
          {!isDesktop &&
          <Grid container={true} direction="row" justify="space-around">
            {this.GoFullScreenButton()}
            {controllersAreSupported() && this.ControllerButton()}
          </Grid>
          }
          {this.props.isInstallable && <MenuItem onClick={this.showInstallPrompt}>Add to home screen</MenuItem>}
          {this.props.isInstallable && <Divider/>}
          <MenuItem onClick={this.print}>
            Print
          </MenuItem>
          {this.props.topView === TopView.Sheet &&
          <MenuItem onClick={this.refresh}>
              Refresh
          </MenuItem>
          }
          <MenuItem onClick={this.removeVisibleOfflineSounds}>
            Remove visible downloads
          </MenuItem>
          <MenuItem onClick={this.purgeOfflineSounds}>
            Remove all downloads
          </MenuItem>
          {this.props.signedInUser && <Divider/>}
          {this.props.signedInUser && <MenuItem onClick={this.signOut}>Sign out</MenuItem>}
        </Menu>
      </Toolbar>
    )
  }

  private get title() {
    switch (this.props.topView) {
      case TopView.Sheet:
        return this.props.sheetTitle
      case TopView.AllSheets:
      case TopView.QuickMarkers:
      case TopView.Settings:
      default:
        return getTopViewLabel(this.props.topView)
    }
  }

  private refresh = () => {
    this.closeMenu()
    this.props.refresh()
  }

  private print = () => {
    this.closeMenu()
    this.props.print()
  }

  private removeVisibleOfflineSounds = () => {
    this.closeMenu()
    if (window.confirm('Really remove visible downloads?')) {
      this.props.removeVisibleOfflineSounds()
    }
  }

  private purgeOfflineSounds = () => {
    this.closeMenu()
    if (window.confirm('Really remove all downloads in this Jam Pad?')) {
      this.props.purgeOfflineSounds()
    }
  }

  private menuToolbarClicked = (evt: React.MouseEvent<any>) => {
    // TODO Get rid of blue focus line ... userSelect: none?
  }

  private signOut = () => {
    this.closeMenu()
    this.props.signOut()
  }

  private showInstallPrompt = () => {
    this.closeMenu()
    this.props.showInstallPrompt()
  }

  private openMenu = (event: React.MouseEvent<{}>) => {
    this.setState({
      anchorEl: event.currentTarget as HTMLButtonElement
    })
  }

  private closeMenu = () => {
    this.setState({
      anchorEl: undefined
    })
  }

  private GoFullScreenButton() {
    return (
      <Tooltip title="Enter full-screen mode">
        <div>
          <IconButton color="inherit" onClick={this.goFullScreen}>
            <FullscreenIcon color="inherit"/>
          </IconButton>
        </div>
      </Tooltip>
    )
  }

  private goFullScreen = () => {
    this.closeMenu()
    this.props.goFullScreen()
  }

  private GoToActiveSoundMarkerButton() {
    return (
      <Tooltip title="Go to active marker">
        <div>
          <IconButton color="inherit" onClick={this.props.goToActiveSoundMarker} disabled={!this.props.hasActiveMarker}>
            <LocationSearchingIcon color="inherit"/>
          </IconButton>
        </div>
      </Tooltip>
    )
  }

  private EditSheetButton() {
    return (
      <Tooltip title={this.props.isInEditMode ? 'Leave edit mode' : 'Edit current sheet'}>
        <div>
          <IconButton color="inherit" onClick={this.toggleEditMode} disabled={this.props.sheetIsReadOnly}>
            <EditIcon color={this.props.isInEditMode ? 'secondary' : 'inherit'}/>
          </IconButton>
        </div>
      </Tooltip>
    )
  }

  private OpenFilterButton() {
    return (
      <Tooltip title="Open filter panel"
               disableFocusListener={true}>
        <IconButton color={this.props.filterIsActive ? 'secondary' : 'inherit'}
                    onClick={this.props.openFilterPanel}>
          <FilterListIcon/>
        </IconButton>
      </Tooltip>
    )
  }

  private PlaylistModeButton() {
    const classes = this.props.classes
    return (
      <Tooltip title={getPlaylistModeTooltip(this.props.playlistMode, this.props.teaserLengthInSeconds)}>
        <Badge
          badgeContent={this.props.playlistMode === PlaylistMode.OnTeaser ? this.props.teaserLengthInSeconds : undefined}
          color="secondary" classes={{badge: classes.badge, invisible: classes.invisible}}>
          <IconButton color="inherit" onClick={this.rotatePlaylistMode}>
            <PlaylistPlayIcon color={this.props.playlistMode === PlaylistMode.Off ? 'inherit' : 'secondary'}/>
          </IconButton>
        </Badge>
      </Tooltip>

    )
  }

  private ControllerButton() {
    return (
      <Tooltip title="Connect to supported Bluetooth Low Energy controllers"
               disableFocusListener={true}>
        <IconButton color="inherit" onClick={this.toggleControllerConnection}>
          {this.controllerIcon}
        </IconButton>
      </Tooltip>
    )
  }

  private MakeAvailableOfflineButton = () => {
    const {numOfflineSounds, numSounds, isDownloading, classes} = this.props
    const allAreAvailableOffline = this.allVisibleSoundsAvailableOffline
    const title = isDownloading
      ? 'Cancel downloads'
      : numSounds > 0 && allAreAvailableOffline
        ? 'Remove downloads of all currently visible files'
        : `Download files (${numOfflineSounds} of ${numSounds} already downloaded)`
    return (
      <Tooltip title={title}>
        <div className={classes.offlineButtonWrapper}>
          <IconButton color={allAreAvailableOffline ? 'secondary' : 'inherit'}
                      onClick={
                        isDownloading
                          ? this.props.cancelDownload
                          : allAreAvailableOffline ? this.removeVisibleOfflineSounds : this.props.makeSoundsAvailableOffline
                      }
                      disabled={!isDownloading && (!this.props.isOnline || numSounds === 0)}
                      classes={{root: isDownloading ? classes.offlineCancelButton : undefined}}>
            {isDownloading ? <ClearIcon/> : <CloudDownloadIcon/>}
          </IconButton>
          {isDownloading && this.DownloadProgress()}
        </div>
      </Tooltip>
    )
  }

  private get allVisibleSoundsAvailableOffline() {
    return this.props.numOfflineSounds === this.props.numSounds
  }

  private DownloadProgress = () => {
    const classes = this.props.classes
    if (this.props.downloadProgress === undefined) {
      return (
        <CircularProgress
          variant="indeterminate"
          color="inherit"
          className={classes.offlineProgress}
        />
      )
    } else {
      return (
        <CircularProgress
          variant="determinate"
          color="inherit"
          value={this.props.downloadProgress}
          className={classes.offlineProgress}
        />
      )
    }
  }

  private toggleEditMode = () => {
    if (this.props.isInEditMode) {
      this.props.leaveEditMode()
    } else {
      this.props.enterEditMode()
    }
  }

  private toggleControllerConnection = () => {
    if (this.props.controllerConnectionState === ControllerConnectionState.Disconnected) {
      this.props.connectController()
    } else {
      this.props.disconnectController()
    }
  }

  private get controllerIcon() {
    switch (this.props.controllerConnectionState) {
      case ControllerConnectionState.Disconnected:
        return <BluetoothIcon/>
      case ControllerConnectionState.Connecting:
        return <BluetoothSearchingIcon/>
      case ControllerConnectionState.Connected:
      default:
        return <BluetoothConnectedIcon color="secondary"/>
    }
  }

  private rotatePlaylistMode = () => {
    this.props.setPlaylistMode(getNextPlaylistMode(this.props.playlistMode))
  }
}

export const CustomToolbar = withWidth()(withStyles(styles)(RawCustomToolbar))