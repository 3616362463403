/* eslint import/no-webpack-loader-syntax: off */
import * as firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'
import {UniqueIdGenerator} from '../util/UniqueIdGenerator'
import {EMPTY, from, Observable, of, Subject} from 'rxjs'
import {mergeMap, switchMap} from 'rxjs/operators'
import * as log from 'loglevel'
import Dexie from 'dexie'
import {SheetChange} from '../model/SheetChange'
// @ts-ignore
import * as Y from 'yjs'
import {TextChange} from '../model/TextChange'
import {SheetCommit} from '../model/SheetCommit'
import {Instant} from 'js-joda'
import uuidv4 from 'uuid/v4'
import {LocalState} from '../model/LocalState'
import {uniq} from 'lodash/fp'
import {checkIfOnline} from '../util/layout-util'
import {remoteSpaceDocRef, remoteDb} from '../util/firebase'

type Transaction = firebase.firestore.Transaction

class SheetDatabase extends Dexie {
  public changes: Dexie.Table<SheetChange, number>
  public commits: Dexie.Table<SheetCommit, string>
  public state: Dexie.Table<LocalState, string>

  public constructor() {
    super("sheetDb")
    this.version(1).stores({
      changes: "++id,sheetId",
      commits: "&id,sheetId,synced",
      state: ""
    })
    this.changes = this.table("changes")
    this.commits = this.table("commits")
    this.state = this.table("state")
  }
}

const localDb = new SheetDatabase()

const remoteCommitsCollectionRef = () => remoteSpaceDocRef().collection('commits')
const sheetsUpdatedSubject = new Subject<string[]>()

export function sheetsUpdated(): Observable<string[]> {
  return sheetsUpdatedSubject.asObservable()
}

export function saveSheetChanges(sheetChanges: SheetChange[]) {
  localDb.transaction('rw', localDb.changes, () => {
    localDb.changes.bulkAdd(sheetChanges)
  })
}

async function getSheetYDoc(sheetId: string): Promise<any | undefined> {
  const commits: SheetCommit[] = await localDb.transaction('r', localDb.commits, async () => {
    const result = await localDb.commits.where({sheetId})
    return result.toArray()
  })
  if (commits.length === 0) {
    return undefined
  }
  log.debug('# Applying commits...')
  const doc = new Y.Doc()
  commits.forEach(commit => {
    Y.applyUpdate(doc, commit.update)
  })
  log.debug('Applying commits finished')
  return doc
}

export async function commitAndSync(sheetId: string) {
  await commitPendingSheetChanges(sheetId)
  sync()
}

async function commitPendingSheetChanges(sheetId: string): Promise<void> {
  const changes: SheetChange[] = await localDb.transaction('r', localDb.changes, async () => {
    const result = await localDb.changes.where({sheetId})
    return result.toArray()
  })
  if (changes.length === 0) {
    return
  }
  const doc = await getSheetYDoc(sheetId)
  const text = getDocYText(doc)
  const stateVecBefore = Y.encodeStateVector(doc)
  log.debug('# Applying recent changes...')
  changes.forEach(change => {
    applyChangeToYText(text, change.textChange)
  })
  log.trace(text.toString())
  const newUpdate = Y.encodeStateAsUpdate(doc, stateVecBefore)
  return await localDb.transaction('rw', [localDb.changes, localDb.commits], async () => {
    await localDb.commits.add({
      id: uuidv4(),
      sheetId,
      timestamp: Instant.now().epochSecond(),
      update: newUpdate,
      synced: 0
    })
    await localDb.changes.bulkDelete(changes.map(ch => ch.id!))
  })
}

function applyChangeToYText(text: any, change: TextChange) {
  if (change.delLength > 0) {
    text.delete(change.start, change.delLength)
  }
  if (change.insText.length > 0) {
    text.insert(change.start, change.insText)
  }
}

export async function getLocalSheetIds(): Promise<string[]> {
  // https://github.com/dfahlander/Dexie.js/issues/1030
  if ((await localDb.commits.count()) === 0) {
    return []
  } else {
    return await localDb.commits.orderBy('sheetId').uniqueKeys() as string[]
  }
}

// If no sheetId is given, a random one will be generated. Does nothing if sheet already exists. Returns ID of new sheet.
// TODO undefined maybe unnecessary in future
export async function createNewSheet(sheetId?: string, initialContent?: string): Promise<string | undefined> {
  if (sheetId) {
    // Sheet ID given
    const offlineSheetsIds = await getLocalSheetIds()
    const sheetExists = offlineSheetsIds.indexOf(sheetId) !== -1
    if (sheetExists) {
      return sheetId
    } else {
      await saveFirstLocalCommit(sheetId, initialContent || '')
      return sheetId
    }
  } else {
    // No sheet ID given
    const newSheetId = UniqueIdGenerator.generate()
    await saveFirstLocalCommit(newSheetId, initialContent || '')
    return newSheetId
  }
}

async function saveFirstLocalCommit(sheetId: string, sheetContent: string): Promise<string> {
  const commit = createFirstCommit(sheetId, sheetContent)
  await localDb.transaction('rw', [localDb.commits], async () => {
    await localDb.commits.add(commit)
  })
  return sheetId
}

function createFirstCommit(sheetId: string, sheetContent: string): SheetCommit {
  const doc = new Y.Doc()
  const text = getDocYText(doc)
  text.insert(0, sheetContent)
  const update = Y.encodeStateAsUpdate(doc)
  return {
    id: uuidv4(),
    sheetId,
    timestamp: Instant.now().epochSecond(),
    update,
    synced: 0
  }
}

export const sync = async () => {
  if (!checkIfOnline()) {
    return
  }
  // Sync from server
  const localState = await localDb.transaction('r', [localDb.state], async () => {
    const result = await localDb.state.get('current')
    return result || {syncClock: 0}
  })
  const newRemoteCommitsQuery = remoteCommitsCollectionRef().where('syncClock', '>', localState.syncClock)
  const newRemoteCommitSnapshots = await newRemoteCommitsQuery.get()
  if (newRemoteCommitSnapshots.docs.length > 0) {
    const newLocalCommits = await localDb.transaction('rw', [localDb.state, localDb.commits], async () => {
      const newRemoteCommits = newRemoteCommitSnapshots.docs.map(s => s.data())
      const newRemoteSyncClock = Math.max(...newRemoteCommits.map(c => c.syncClock as number))
      const newLocalCommits = newRemoteCommits.map(c => {
        delete c.syncClock
        return {
          ...c,
          synced: 1,
          update: c.update.toUint8Array()
        } as SheetCommit
      })
      await localDb.commits.bulkAdd(newLocalCommits)
      await updateLocalSyncClock(newRemoteSyncClock)
      return newLocalCommits
    })
    const updatedSheetIds = uniq(newLocalCommits.map(c => c.sheetId))
    sheetsUpdatedSubject.next(updatedSheetIds)
  }
  // Sync to server
  const newLocalCommits: SheetCommit[] = await localDb.transaction('r', localDb.commits, async () => {
    const result = await localDb.commits.where({synced: 0})
    return result.toArray()
  })
  if (newLocalCommits.length > 0) {
    const newRemoteSyncClock = await remoteDb.runTransaction(async tx => {
      const newRemoteSyncClock = await incrementRemoteSyncClock(tx)
      newLocalCommits.forEach(commit => {
        saveAsRemoteCommit(commit, tx, newRemoteSyncClock)
      })
      return newRemoteSyncClock
    })
    await localDb.transaction('rw', [localDb.commits, localDb.state], async () => {
      newLocalCommits.forEach(commit => {
        localDb.commits.update(commit.id, {synced: 1})
      })
      updateLocalSyncClock(newRemoteSyncClock)
    })
  }
}

function updateLocalSyncClock(newRemoteSyncClock: number) {
  return localDb.state.put({syncClock: newRemoteSyncClock}, 'current')
}

function saveAsRemoteCommit(commit: SheetCommit, tx: Transaction, newRemoteSyncClock: number) {
  delete commit.synced
  const commitDocRef = remoteCommitsCollectionRef().doc(commit.id)
  tx.set(commitDocRef, {
    ...commit,
    update: firebase.firestore.Blob.fromUint8Array(commit.update),
    syncClock: newRemoteSyncClock
  })
}

async function incrementRemoteSyncClock(tx: Transaction) {
  const spaceDoc = await tx.get(remoteSpaceDocRef())
  const newRemoteSyncClock: number = spaceDoc.exists ? spaceDoc.data()!.syncClock + 1 : 1
  if (spaceDoc.exists) {
    tx.update(remoteSpaceDocRef(), {syncClock: newRemoteSyncClock})
  } else {
    tx.set(remoteSpaceDocRef(), {syncClock: newRemoteSyncClock})
  }
  return newRemoteSyncClock
}

export async function getOfflineSheetContent(sheetId: string): Promise<string | undefined> {
  log.debug('Obtaining content for sheet', sheetId)
  const doc = await getSheetYDoc(sheetId)
  if (!doc) {
    return undefined
  }
  return getDocYText(doc).toString()
}

export function getSheetContents(sheetIds: string[]): Observable<{ sheetId: string, content: string }> {
  return from(sheetIds).pipe(
    mergeMap(sheetId => {
      return from(getOfflineSheetContent(sheetId)).pipe(
        switchMap(content => {
          if (content === undefined) {
            return EMPTY
          }
          return of({sheetId, content})
        })
      )
    })
  )
}

function getDocYText(doc: any) {
  return doc.getText('content')
}