import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {CustomToolbar, DataProps, DispatchProps} from '../components/CustomToolbar'
import {
  currentlyVisibleSheetIdSelector,
  currentlyVisibleSheetTypeSelector,
  isInSheetEditModeSelector,
  currentlyVisibleSheetIsReadOnlySelector,
  currentlyVisibleSheetTitleSelector
} from '../store/sheet/selectors'
import {
  enterSheetEditMode,
  leaveSheetEditMode, refresh,
} from '../store/sheet/actions'
import {
  activeSheetSoundMarkerIndexSelector, activeSoundMarkerSelector,
  filteredCurrentlyVisibleSoundAddressSetSelector,
  filteredCurrentlyVisibleOfflineSoundAddressesSelector
} from '../store/player/selectors'
import {
  goToActiveSoundMarker,
  showFilterPanel,
  showInstallPrompt, showSwipeableDrawer,
  signOut,
  switchToNextLogo, togglePersistentDrawer
} from '../store/layout/actions'
import {currentLogoSelector, isOnlineSelector, topViewSelector} from '../store/layout/selectors'
import {setPlaylistMode} from '../store/settings/actions'
import {connectController, disconnectController} from '../store/controller/actions'
import {filterIsActiveSelector} from '../store/settings/selectors'
import {TopView} from '../model/TopView'
import {
  cancelSoundDownload,
  makeVisibleSoundsAvailableOffline,
  purgeOfflineCache,
  removeVisibleOfflineSounds
} from '../store/player/actions'

function mapStateToProps(state: ApplicationState): DataProps {
  const topView = topViewSelector(state)
  return {
    editButtonEnabled: topView === TopView.Sheet && currentlyVisibleSheetIdSelector(state) !== undefined,
    offlineButtonEnabled: topView !== TopView.Settings,
    sheetIsReadOnly: currentlyVisibleSheetIsReadOnlySelector(state),
    isInEditMode: isInSheetEditModeSelector(state),
    numOfflineSounds: filteredCurrentlyVisibleOfflineSoundAddressesSelector(state).length,
    numSounds: filteredCurrentlyVisibleSoundAddressSetSelector(state).size,
    downloadProgress: state.player.soundDownloadProgress,
    isDownloading: state.player.isDownloadingSounds,
    currentSoundMarkerIndex: activeSheetSoundMarkerIndexSelector(state),
    isOnline: isOnlineSelector(state),
    persistentDrawerIsOpen: state.layout.persistentDrawerIsOpen,
    sheetTitle: currentlyVisibleSheetTitleSelector(state),
    isInstallable: state.layout.installPromptAvailable,
    signedInUser: state.layout.signedInUser,
    playlistMode: state.settings.playlistMode,
    controllerConnectionState: state.controller.connectionState,
    teaserLengthInSeconds: state.settings.teaserLengthInSeconds,
    filterIsActive: filterIsActiveSelector(state),
    topView,
    sheetType: currentlyVisibleSheetTypeSelector(state),
    Logo: currentLogoSelector(state),
    hasActiveMarker: activeSoundMarkerSelector(state) !== undefined,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    enterEditMode: () => dispatch(enterSheetEditMode()),
    leaveEditMode: () => dispatch(leaveSheetEditMode()),
    makeSoundsAvailableOffline: () => dispatch(makeVisibleSoundsAvailableOffline()),
    cancelDownload: () => dispatch(cancelSoundDownload()),
    purgeOfflineSounds: () => dispatch(purgeOfflineCache()),
    removeVisibleOfflineSounds: () => dispatch(removeVisibleOfflineSounds()),
    togglePersistentDrawer: () => dispatch(togglePersistentDrawer()),
    showSwipeableDrawer: () => dispatch(showSwipeableDrawer(true)),
    showInstallPrompt: () => dispatch(showInstallPrompt()),
    signOut: () => dispatch(signOut()),
    setPlaylistMode: playlistMode => dispatch(setPlaylistMode({playlistMode})),
    connectController: () => dispatch(connectController()),
    disconnectController: () => dispatch(disconnectController()),
    openFilterPanel: () => dispatch(showFilterPanel(true)),
    switchToNextLogo: () => dispatch(switchToNextLogo()),
    goToActiveSoundMarker: () => dispatch(goToActiveSoundMarker()),
    refresh: () => dispatch(refresh()),
  }
}

export const CustomToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(CustomToolbar)