import * as actions from './actions'
import {
  cancelSoundDownload,
  changePublicSoundAddress,
  handleRegionEndReached,
  makeSoundsAvailableOffline, offlineSoundDequeued,
  play,
  playbackRateChanged,
  playNextDeviceSoundMarker,
  playNextSheetSoundMarker,
  playNextSoundMarker,
  playPositionChanged,
  playPreviousDeviceSoundMarker,
  playPreviousSheetSoundMarker,
  playSoundMarker,
  processOfflineSoundQueue,
  seek,
  soundDownloadProgressed,
  soundDownloadStateChanged,
  soundLoaded,
  soundLoading,
  soundLoadingFailed,
  soundMarkerActivated,
  soundMetadataLoaded,
  soundsEnqueuedForOffline,
  streamingSoundUrlChanged,
  switchedBackToOtherMarkerList,
  volumeChanged
} from './actions'
import {ActionType, isActionOf} from 'typesafe-actions'
import {ApplicationState} from '../ApplicationState'
import {combineEpics, Epic} from 'redux-observable'
import * as soundStreamSourceService from '../../services/SoundStreamSourceService'
import {
  catchError,
  distinctUntilChanged,
  filter,
  ignoreElements,
  map,
  mapTo,
  switchMap,
  takeUntil,
  tap,
  timeout
} from 'rxjs/operators'
import {PlayState} from '../../services/SoundPlayer'
import * as soundPlayer from '../../services/SoundPlayer'
import {concat, defer, EMPTY, from, merge, Observable, of, Subject, throwError, TimeoutError, timer} from 'rxjs'
import {handleError, handleInfo} from '../layout/actions'
import {Duration} from 'js-joda'
import {
  currentlyVisibleOrAllSheetSoundMarkersSelector,
  currentlyVisibleSheetTitleSelector,
  sheetInfosSelector
} from '../sheet/selectors'
import {
  activeSheetSoundMarkerSelector,
  activeSoundMarkerSelector,
  currentPlaylistTypeSelector,
  currentSoundAddressAndPositionSelector,
  downloadFileNameSelector,
  filteredCurrentlyVisibleOfflineSoundAddressesSelector,
  filteredCurrentlyVisibleOnlineSoundAddressesSelector,
  filteredDeviceSoundMarkersSelector, isDownloadingSoundsSelector,
  nextDeviceSoundMarkerSelector,
  nextSheetSoundMarkerSelector,
  nextSoundInOfflineQueueSelector,
  nextSoundMarkerInCurrentSoundSelector,
  nextSoundMarkerSelector,
  previousDeviceSoundMarkerSelector,
  previousSheetSoundMarkerSelector,
  publicSoundAddressSelector,
  qualifiedActiveDeviceSoundMarkerSelector,
  soundMarkerFromCurrentUrlSelector,
  soundMetadataSelector,
  soundPositionInSecondsSelector,
  streamingSoundUrlSelector
} from './selectors'
import {SoundStreamSource} from '../../model/SoundStreamSource'
import * as soundMetadataService from '../../services/SoundMetadataService'
import {deviceSoundMarkersSelector} from '../marker/selectors'
import {SoundMarker} from '../../model/SoundMarker'
import {SoundAddressAndPosition} from '../../model/SoundAddressAndPosition'
import {playHighClick} from '../../util/beep'
import {SoundMarkerListType} from '../../model/SoundMarkerListType'
import {generateLiveClipXml} from '../../util/export/live'
import {saveAs} from 'file-saver'
import {generateMarkerTitle} from '../../util/sound-marker-util'
import {generateSoundFileName} from '../../util/sound-util'
import {downloadCompleteFileViaStreaming, downloadPartialFile} from '../../util/download-util'
import {generateReaperItemChunk} from '../../util/export/reaper'
import {PlaylistMode} from '../../model/PlaylistMode'
import {ListInfo} from '../../model/ListInfo'
import {IndexedSoundMarkerWithListInfo} from '../../model/IndexedSoundMarkerWithListInfo'
import {convertPlaylistToMarkerListType, PlaylistType} from '../../model/PlaylistType'
import {parseSoundMarkerFromArbitraryAddress} from '../../util/sound-marker-uri-util'

const extendedActions = {
  ...actions,
  handleError,
  handleInfo,
}
type PlayerAction = ActionType<typeof extendedActions>
type PlayerEpic = Epic<PlayerAction, PlayerAction, ApplicationState>

const commitTypedPublicSoundAddressEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.commitTypedPublicSoundAddress)),
  map(action => {
    return changePublicSoundAddress({publicAddress: state$.value.player.typedPublicSoundAddress || ''})
  })
)

const changePublicSoundAddressEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.changePublicSoundAddress)),
  switchMap(action => {
    if (!action.payload.publicAddress) {
      return EMPTY
    }
    return of(playSoundMarker({
      soundMarker: parseSoundMarkerFromArbitraryAddress(action.payload.publicAddress),
      playlistType: undefined
    }))
  })
)

function getSoundLoadingErrorMessage(error: any) {
  if (error instanceof TimeoutError) {
    return 'Okay, that took too long. I give up.'
  } else {
    switch (error) {
      case 'MEDIA_ERR_SRC_NOT_SUPPORTED':
        return "Couldn't load sound (quota exceeded, file gone, format unsupported, ...)"
      case 'MEDIA_ERR_NETWORK':
        return "Network error occurred"
      case 'MEDIA_ERR_DECODE':
        return "Decoding error occurred"
      default:
        return `Error occurred: ${error}`
    }
  }
}

function fetchMetadata(soundStreamSource: SoundStreamSource) {
  return soundMetadataService.fetchSoundMetadata(soundStreamSource).pipe(
    map(soundMetadata => soundMetadataLoaded({soundMetadata})),
    catchError(error => {
      const errorMsg = error instanceof TimeoutError
        ? 'Reading metadata took too long'
        : `Error while reading metadata`
      return merge(
        of(soundMetadataLoaded({soundMetadata: {}})),
        throwError(errorMsg),
      )
    })
  )
}


const playSoundMarkerEpic: PlayerEpic =
  (action$, state$) => action$.pipe(
    filter(isActionOf(actions.playSoundMarker)),
    switchMap(action => {
      const soundMarker = action.payload.soundMarker
      const currentPlaylist = currentPlaylistTypeSelector(state$.value)
      return of(
        soundMarkerActivated({
          soundMarker,
          playlistType: action.payload.playlistType,
          changeListAndPosition: true,
          positionBeforeMarkerListSwitch: currentPlaylist && action.payload.playlistType !== currentPlaylist
            ? currentSoundAddressAndPositionSelector(state$.value)
            : undefined
        }),
        play({
          publicSoundAddress: soundMarker.publicSoundAddress,
          positionInSeconds: soundMarker.positionInSeconds
        }),
      )
    }),
  )

const playEpic: PlayerEpic =
  (action$, state$) => action$.pipe(
    filter(isActionOf(actions.play)),
    switchMap(action => {
      return from(soundStreamSourceService.determineStreamSource(action.payload.publicSoundAddress)).pipe(
        catchError(error => throwError(`Error while building stream URL: ${error}`)),
        switchMap(soundStreamSource => {
          const cancelHeadsUpTimerSubject = new Subject<void>()
          const streamingSoundUrl = soundStreamSource.url.toString()
          const newSoundNeedsToBeLoaded = streamingSoundUrl !== state$.value.player.streamingSoundUrl
          return concat(
            newSoundNeedsToBeLoaded ? of(soundLoading(), streamingSoundUrlChanged({url: streamingSoundUrl})) : EMPTY,
            merge(
              timer(5000).pipe(
                takeUntil(cancelHeadsUpTimerSubject),
                map(() => handleInfo('Loading sound takes an unusually long time...'))
              ),
              timer(20000).pipe(
                takeUntil(cancelHeadsUpTimerSubject),
                map(() => handleInfo('Man, it really takes a long time...'))
              ),
              soundPlayer.loadAndPlayFrom(
                soundStreamSource.url,
                Duration.ofSeconds(action.payload.positionInSeconds || 0),
                state$.value.player.playbackRate,
                state$.value.player.volume
              ).pipe(
                timeout(30000),
                ignoreElements(),
                tap({
                  complete: () => cancelHeadsUpTimerSubject.next(),
                  error: () => cancelHeadsUpTimerSubject.next()
                }),
                catchError(error => merge(
                  of(soundLoadingFailed()),
                  throwError(getSoundLoadingErrorMessage(error))
                )),
              )
            ),
            defer(() => {
              return of(soundLoaded({soundLengthInSeconds: soundPlayer.soundLength().seconds()}))
            }),
            // Do this after loading sound, not in parallel. We don't want to annoy the servers too much.
            fetchMetadata(soundStreamSource).pipe(
              catchError(e => {
                // We don't want this annoying red error popup just because reading metadata failed. Probably just
                // a CORS issue.
                console.log(e)
                return EMPTY
              })
            )
          )
        }),
        catchError(error => of(handleError(error)))
      )
    }),
  )


const soundMarkerActivatedEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.soundMarkerActivated)),
  filter(action => {
    const nav = navigator as any
    const win = window as any
    if (!nav.mediaSession) {
      return false
    }
    const soundMarker = action.payload.soundMarker
    const listInfo = soundMarker.listInfo
    // TODO Additionally integrate sound meta data as soon as available
    nav.mediaSession.metadata = new win.MediaMetadata({
      title: generateMarkerTitle(soundMarker, listInfo ? listInfo.index : undefined),
      // artist: 'Jam Pad', // TODO Also use space name as soon as available
      album: getAlbumName(state$.value, listInfo),
      artwork:
        [
          // TODO Add space artwork as soon as available
          {src: '/android-chrome-192x192.png', sizes: '192x192', type: 'image/png'},
          {src: '/android-chrome-512x512.png', sizes: '512x512', type: 'image/png'},
        ]
    })
    return false
  })
)

function getAlbumName(state: ApplicationState, listInfo?: ListInfo) {
  if (!listInfo) {
    return undefined
  }
  switch (listInfo.type) {
    case SoundMarkerListType.Device:
      return 'Quick markers'
    case SoundMarkerListType.Sheet: {
      const sheetInfo = sheetInfosSelector(state)[listInfo.sheetId]
      if (!sheetInfo) {
        return currentlyVisibleSheetTitleSelector(state)
      }
      return sheetInfo.title
    }
  }
}

const togglePlayPauseEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.togglePlayPause)),
  switchMap(action => {
    if (!state$.value.player.soundLoaded) {
      return of(playNextSoundMarker())
    }
    if (soundPlayer.playState() === PlayState.Playing) {
      soundPlayer.pause()
    } else {
      soundPlayer.play()
    }
    return EMPTY
  })
)

const markerUrlCopiedToClipboardEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.markerUrlCopiedToClipboard)),
  map(action => {
    if (action.payload.successful) {
      return handleInfo('Marker URL copied to clipboard')
    } else {
      return handleError('Marker URL could not be copied to clipboard')
    }
  })
)

const seekEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.seek)),
  map(action => {
    const soundLengthInSeconds = state$.value.player.soundLengthInSeconds
    const fixedPosition = Math.max(0, Math.min(action.payload.playPositionInSeconds, soundLengthInSeconds))
    soundPlayer.seek(Duration.ofSeconds(fixedPosition))
    // Report optimistically
    return playPositionChanged({playPositionInSeconds: fixedPosition})
  })
)

const changePlaybackRateEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.changePlaybackRate)),
  map(action => {
    const actualValue = Math.max(0.5, Math.min(action.payload.playbackRate, 4))
    soundPlayer.setPlaybackRate(actualValue)
    return playbackRateChanged({playbackRate: actualValue})
  })
)

const changeVolumeEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.changeVolume)),
  map(action => {
    const actualValue = Math.max(0, Math.min(action.payload.volume, 100))
    soundPlayer.setVolume(actualValue)
    return volumeChanged({volume: actualValue})
  })
)

const seekRelativelyEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.seekRelatively)),
  map(action => {
    const amountInSeconds = action.payload.amountInSeconds || state$.value.settings.seekDistanceInSeconds
    return seek({
      playPositionInSeconds: state$.value.player.soundPositionInSeconds + action.payload.direction * amountInSeconds
    })
  })
)

const handleRegionEndReachedEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.handleRegionEndReached)),
  switchMap(action => {
    soundPlayer.pause()
    // Auto-play next sound marker if enabled
    if (state$.value.settings.playlistMode === PlaylistMode.Off) {
      return EMPTY
    }
    const nextSoundMarker = nextSoundMarkerSelector(state$.value)
    if (!nextSoundMarker) {
      return EMPTY
    }
    return timer(0).pipe(
      mapTo(playSoundMarker({soundMarker: nextSoundMarker, playlistType: currentPlaylistTypeSelector(state$.value)}))
    )
  })
)

const playPreviousSoundMarkerEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.playPreviousSoundMarker)),
  map(action => {
    switch (currentPlaylistTypeSelector(state$.value)) {
      case PlaylistType.Sheet:
      case PlaylistType.AllSheets:
        return playPreviousSheetSoundMarker()
      case PlaylistType.Device:
        return playPreviousDeviceSoundMarker()
      case undefined:
      default:
        return filteredDeviceSoundMarkersSelector(state$.value).length > 0
          ? playPreviousDeviceSoundMarker()
          : playPreviousSheetSoundMarker()
    }
  })
)

const playNextSoundMarkerEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.playNextSoundMarker)),
  map(action => {
    switch (currentPlaylistTypeSelector(state$.value)) {
      case PlaylistType.Sheet:
      case PlaylistType.AllSheets:
        return playNextSheetSoundMarker()
      case PlaylistType.Device:
        return playNextDeviceSoundMarker()
      case undefined:
      default:
        return filteredDeviceSoundMarkersSelector(state$.value).length > 0
          ? playNextDeviceSoundMarker()
          : playNextSheetSoundMarker()
    }
  })
)

const playPreviousSheetSoundMarkerEpic = createPlayPreviousSoundMarkerEpic(
  action$ => action$.pipe(filter(isActionOf(actions.playPreviousSheetSoundMarker))),
  activeSheetSoundMarkerSelector,
  previousSheetSoundMarkerSelector,
  SoundMarkerListType.Sheet
)

const playNextSheetSoundMarkerEpic = createPlayNextSoundMarkerEpic(
  action$ => action$.pipe(filter(isActionOf(actions.playNextSheetSoundMarker))),
  nextSheetSoundMarkerSelector,
  SoundMarkerListType.Sheet
)

const playPreviousDeviceSoundMarkerEpic = createPlayPreviousSoundMarkerEpic(
  action$ => action$.pipe(filter(isActionOf(actions.playPreviousDeviceSoundMarker))),
  qualifiedActiveDeviceSoundMarkerSelector,
  previousDeviceSoundMarkerSelector,
  SoundMarkerListType.Device
)

const playNextDeviceSoundMarkerEpic = createPlayNextSoundMarkerEpic(
  action$ => action$.pipe(filter(isActionOf(actions.playNextDeviceSoundMarker))),
  nextDeviceSoundMarkerSelector,
  SoundMarkerListType.Device
)

function createPlayPreviousSoundMarkerEpic<T>(
  filterActions: (action$: Observable<PlayerAction>) => Observable<PlayerAction>,
  selectActiveSoundMarker: (state: ApplicationState) => IndexedSoundMarkerWithListInfo | undefined,
  previousSoundMarkerSelector: (state: ApplicationState) => IndexedSoundMarkerWithListInfo | undefined,
  soundMarkerListType: SoundMarkerListType
): PlayerEpic {
  return (action$, state$) => filterActions(action$).pipe(
    switchMap(() => {
      const continueAction$ = continueWhereLeftOffIfApplicable(state$.value, soundMarkerListType)
      if (continueAction$) {
        return continueAction$
      }
      const activeSoundMarker = selectActiveSoundMarker(state$.value)
      const currentSoundPositionInSeconds = soundPositionInSecondsSelector(state$.value)
      const distanceToActiveSoundMarkerInSeconds = activeSoundMarker
        ? currentSoundPositionInSeconds - (activeSoundMarker.positionInSeconds || 0)
        : undefined
      const previousSoundMarker = previousSoundMarkerSelector(state$.value)
      if (previousSoundMarker && (
        distanceToActiveSoundMarkerInSeconds === undefined ||
        distanceToActiveSoundMarkerInSeconds <= state$.value.settings.playPreviousMarkerIfWithinSeconds
      )) {
        // Really play previous marker
        return of(playSoundMarker({
          soundMarker: previousSoundMarker,
          playlistType: currentPlaylistTypeSelector(state$.value)
        }))
      } else if (activeSoundMarker) {
        // Consider current marker as previous marker because already more than n seconds in past
        return of(playSoundMarker({
          soundMarker: activeSoundMarker,
          playlistType: currentPlaylistTypeSelector(state$.value)
        }))
      } else {
        return EMPTY
      }
    })
  )
}

function createPlayNextSoundMarkerEpic<T>(
  filterActions: (action$: Observable<PlayerAction>) => Observable<PlayerAction>,
  selectNextSoundMarker: (state: ApplicationState) => IndexedSoundMarkerWithListInfo | undefined,
  soundMarkerListType: SoundMarkerListType
): PlayerEpic {
  return (action$, state$) => filterActions(action$).pipe(
    switchMap(() => {
      const continueAction$ = continueWhereLeftOffIfApplicable(state$.value, soundMarkerListType)
      if (continueAction$) {
        return continueAction$
      }
      const nextSoundMarker = selectNextSoundMarker(state$.value)
      if (!nextSoundMarker) {
        return EMPTY
      }
      return of(playSoundMarker({
        soundMarker: nextSoundMarker,
        playlistType: currentPlaylistTypeSelector(state$.value)
      }))
    })
  )
}

function continueWhereLeftOffIfApplicable(state: ApplicationState,
                                          nextSoundMarkerListType: SoundMarkerListType): Observable<PlayerAction> | undefined {
  const currentPlaylistType = currentPlaylistTypeSelector(state)
  if (!currentPlaylistType || !state.player.positionBeforeMarkerListSwitch ||
    nextSoundMarkerListType === convertPlaylistToMarkerListType(currentPlaylistType)) {
    return undefined
  }
  // Continue where left off before last marker list switch
  return of(
    switchedBackToOtherMarkerList({
      newList: nextSoundMarkerListType === SoundMarkerListType.Sheet ? PlaylistType.Sheet : PlaylistType.Device,
      continueFrom: state.player.positionBeforeMarkerListSwitch,
      positionOnCurrentMarkerList: currentSoundAddressAndPositionSelector(state)
    }),
    play(state.player.positionBeforeMarkerListSwitch),
  )
}

const makeCurrentSoundAvailableOfflineEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.makeCurrentSoundAvailableOffline)),
  switchMap(action => {
    const currentSoundPublicAddress = publicSoundAddressSelector(state$.value)
    if (!currentSoundPublicAddress) {
      return EMPTY
    }
    return of(makeSoundsAvailableOffline({publicSoundAddresses: [currentSoundPublicAddress]}))
  })
)

const downloadCurrentSoundEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.downloadCurrentSound)),
  filter(action => {
    const streamingSoundUrl = streamingSoundUrlSelector(state$.value)
    const downloadFileName = downloadFileNameSelector(state$.value)
    if (!streamingSoundUrl || !downloadFileName) {
      return false
    }
    downloadCompleteFileViaStreaming(streamingSoundUrl, downloadFileName)
    return false
  })
)

const downloadCurrentMarkerAsLiveClipEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.downloadCurrentMarkerAsLiveClip)),
  filter(action => {
    const activeSoundMarker = activeSoundMarkerSelector(state$.value)
    const activeSoundMetadata = soundMetadataSelector(state$.value)
    if (!activeSoundMarker) {
      return false
    }
    const markerTitle = generateMarkerTitle(activeSoundMarker, activeSoundMarker.index)
    const soundFileName = generateSoundFileName(activeSoundMarker.publicSoundAddress, activeSoundMetadata)
    const liveClipXml = generateLiveClipXml(activeSoundMarker, markerTitle, soundFileName,
      state$.value.settings.teaserLengthInSeconds)
    const blob = new Blob([liveClipXml], {type: 'text/plain;charset=utf-8'})
    saveAs(blob, `${markerTitle}.alc`)
    return false
  })
)

const downloadCurrentMarkerAsReaperItemEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.downloadCurrentMarkerAsReaperItem)),
  filter(action => {
    const activeSoundMarker = activeSoundMarkerSelector(state$.value)
    const activeSoundMetadata = soundMetadataSelector(state$.value)
    if (!activeSoundMarker) {
      return false
    }
    const markerTitle = generateMarkerTitle(activeSoundMarker, activeSoundMarker.index)
    const soundFileName = generateSoundFileName(activeSoundMarker.publicSoundAddress, activeSoundMetadata)
    const reaperItemChunk = generateReaperItemChunk(activeSoundMarker, markerTitle, soundFileName,
      state$.value.settings.teaserLengthInSeconds)
    const blob = new Blob([reaperItemChunk], {type: 'text/plain;charset=utf-8'})
    saveAs(blob, `${markerTitle}.RTrackTemplate`)
    return false
  })
)

const downloadCurrentMarkerAsCroppedFileEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.downloadCurrentMarkerAsCroppedFile)),
  switchMap(action => {
    const streamingSoundUrl = streamingSoundUrlSelector(state$.value)
    const activeSoundMarker = activeSoundMarkerSelector(state$.value)
    if (!activeSoundMarker || !streamingSoundUrl) {
      return EMPTY
    }
    const markerTitle = generateMarkerTitle(activeSoundMarker, activeSoundMarker.index)
    const regionStartInSeconds = activeSoundMarker.positionInSeconds || 0
    const regionLengthInSeconds = activeSoundMarker.regionLengthInSeconds || state$.value.settings.teaserLengthInSeconds
    const soundLengthInSeconds = state$.value.player.soundLengthInSeconds
    if (soundLengthInSeconds <= 0) {
      return EMPTY
    }
    const isBlob = streamingSoundUrl.toString().startsWith('blob')
    const headResponse = fetch(streamingSoundUrl.toString(), {
      method: isBlob ? 'GET' : 'HEAD'
    })
    return from(headResponse).pipe(
      switchMap(response => {
        const contentLengthString = response.headers.get('Content-Length')
        if (!contentLengthString) {
          return EMPTY
        }
        const contentLength = parseInt(contentLengthString, 10)
        if (isNaN(contentLength)) {
          return EMPTY
        }
        const bitrate = 8 * contentLength / soundLengthInSeconds
        const startInBytes = convertSecondsToBytes(regionStartInSeconds, bitrate)
        const lengthInBytes = convertSecondsToBytes(regionLengthInSeconds, bitrate)
        downloadPartialFile(streamingSoundUrl, `${markerTitle}.mp3`, startInBytes, lengthInBytes)
        return EMPTY
      })
    )
  })
)

function convertSecondsToBytes(seconds: number, bitrate: number) {
  return Math.ceil((seconds * bitrate) / 8)
}

const removeOfflineCopyOfCurrentSoundEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.removeOfflineCopyOfCurrentSound)),
  filter(action => {
    const currentSoundPublicAddress = publicSoundAddressSelector(state$.value)
    if (!currentSoundPublicAddress) {
      return false
    }
    soundStreamSourceService.removeOfflineSound(currentSoundPublicAddress)
    return false
  })
)

const playSoundMarkerWheneverSoundMarkerUrlChangesEpic: PlayerEpic = (action$, state$) => state$.pipe(
  map(state => soundMarkerFromCurrentUrlSelector(state)),
  distinctUntilChanged(),
  filter(soundMarker => soundMarker !== undefined),
  switchMap(soundMarker => {
    return of(playSoundMarker({soundMarker: soundMarker!, playlistType: undefined}))
  })
)

const beepWheneverMarkerReachedEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.playPositionChanged)),
  switchMap(action => {
    if (!document.hidden || !state$.value.settings.beepsAreEnabled) {
      return EMPTY
    }
    const currentAddr = publicSoundAddressSelector(state$.value)
    if (!currentAddr) {
      return EMPTY
    }
    const currentSap: SoundAddressAndPosition = {
      publicSoundAddress: currentAddr,
      positionInSeconds: action.payload.playPositionInSeconds
    }
    const deviceMarkers = deviceSoundMarkersSelector(state$.value)
    const sheetMarkers = currentlyVisibleOrAllSheetSoundMarkersSelector(state$.value)
    const marker = findMarkerAtPosition(deviceMarkers, currentSap) || findMarkerAtPosition(sheetMarkers, currentSap)
    if (marker) {
      playHighClick(marker.rating || 1)
    }
    return EMPTY
  })
)

const silentlyActivateNextMarkerEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.playPositionChanged)),
  switchMap(action => {
    const nextSoundMarker = nextSoundMarkerInCurrentSoundSelector(state$.value)
    if (!nextSoundMarker) {
      return EMPTY
    }
    if (action.payload.playPositionInSeconds !== nextSoundMarker.positionInSeconds) {
      return EMPTY
    }
    const activeSoundMarker = activeSoundMarkerSelector(state$.value)
    if (activeSoundMarker && nextSoundMarker.positionInSeconds === activeSoundMarker.positionInSeconds) {
      return EMPTY
    }
    return of(soundMarkerActivated({soundMarker: nextSoundMarker, changeListAndPosition: false}))
  })
)

function findMarkerAtPosition(markers: SoundMarker[], sap: SoundAddressAndPosition) {
  return markers.find(m => m.publicSoundAddress === sap.publicSoundAddress && m.positionInSeconds === sap.positionInSeconds)
}

const detectRegionEndEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.playPositionChanged)),
  switchMap(action => {
    const activeSoundMarker = activeSoundMarkerSelector(state$.value)
    if (activeSoundMarker && activeSoundMarker.publicSoundAddress === state$.value.player.publicSoundAddress &&
      activeSoundMarker.positionInSeconds && activeSoundMarker.regionLengthInSeconds &&
      action.payload.playPositionInSeconds === activeSoundMarker.positionInSeconds + activeSoundMarker.regionLengthInSeconds) {
      return of(handleRegionEndReached())
    }
    const settings = state$.value.settings
    if (settings.playlistMode !== PlaylistMode.OnTeaser) {
      return EMPTY
    }
    const currentSoundMarker = activeSoundMarkerSelector(state$.value)
    if (!currentSoundMarker) {
      return EMPTY
    }
    if (currentSoundMarker.regionLengthInSeconds !== undefined) {
      return EMPTY
    }
    if (action.payload.playPositionInSeconds - (currentSoundMarker.positionInSeconds || 0) !== settings.teaserLengthInSeconds) {
      return EMPTY
    }
    return of(handleRegionEndReached())
  })
)

const purgeOfflineCacheEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.purgeOfflineCache)),
  filter(() => {
    soundStreamSourceService.purgeOfflineCache()
    return false
  })
)

const removeVisibleOfflineSoundsEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.removeVisibleOfflineSounds)),
  filter(() => {
    const offlineSoundAddresses = filteredCurrentlyVisibleOfflineSoundAddressesSelector(state$.value)
    offlineSoundAddresses.forEach(publicAddress => soundStreamSourceService.removeOfflineSound(publicAddress))
    return false
  })
)

const makeVisibleSoundsAvailableOfflineEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.makeVisibleSoundsAvailableOffline)),
  map(action => {
    const onlineSoundAddresses = filteredCurrentlyVisibleOnlineSoundAddressesSelector(state$.value)
    return makeSoundsAvailableOffline({publicSoundAddresses: onlineSoundAddresses})
  })
)

const makeSoundsAvailableOfflineEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.makeSoundsAvailableOffline)),
  switchMap(action => {
    const isDownloading = isDownloadingSoundsSelector(state$.value)
    return concat(
      isDownloading ? EMPTY : of(
        handleInfo('Download started'),
        soundDownloadStateChanged({isDownloading: true})
      ),
      of(soundsEnqueuedForOffline({publicSoundAddresses: action.payload.publicSoundAddresses})),
      isDownloading ? EMPTY : of(processOfflineSoundQueue()),
    )
  })
)

const processOfflineSoundQueueEpic: PlayerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.processOfflineSoundQueue)),
  switchMap(action => {
    const nextSoundAddress = nextSoundInOfflineQueueSelector(state$.value)
    if (!nextSoundAddress) {
      return of(
        soundDownloadStateChanged({isDownloading: false}),
        handleInfo('Download finished')
      )
    }
    const cancelSoundDownloadInvoked = action$.pipe(filter(isActionOf(cancelSoundDownload)))
    return concat(
      of(offlineSoundDequeued({publicSoundAddress: nextSoundAddress})),
      soundStreamSourceService.downloadSound(nextSoundAddress).pipe(
        takeUntil(cancelSoundDownloadInvoked),
        map(progress => soundDownloadProgressed({progress})),
        catchError(error => of(handleError(`${error}`)))
      ),
      of(processOfflineSoundQueue()),
    )
  })
)

export const playerEpic = combineEpics(
  changePublicSoundAddressEpic,
  togglePlayPauseEpic,
  seekEpic,
  seekRelativelyEpic,
  playSoundMarkerEpic,
  changePlaybackRateEpic,
  changeVolumeEpic,
  makeCurrentSoundAvailableOfflineEpic,
  handleRegionEndReachedEpic,
  removeOfflineCopyOfCurrentSoundEpic,
  playPreviousSheetSoundMarkerEpic,
  playNextSheetSoundMarkerEpic,
  playPreviousDeviceSoundMarkerEpic,
  playNextDeviceSoundMarkerEpic,
  playSoundMarkerWheneverSoundMarkerUrlChangesEpic,
  detectRegionEndEpic,
  beepWheneverMarkerReachedEpic,
  silentlyActivateNextMarkerEpic,
  soundMarkerActivatedEpic,
  playPreviousSoundMarkerEpic,
  playNextSoundMarkerEpic,
  playEpic,
  downloadCurrentMarkerAsLiveClipEpic,
  downloadCurrentMarkerAsReaperItemEpic,
  downloadCurrentMarkerAsCroppedFileEpic,
  downloadCurrentSoundEpic,
  markerUrlCopiedToClipboardEpic,
  commitTypedPublicSoundAddressEpic,
  makeVisibleSoundsAvailableOfflineEpic,
  makeSoundsAvailableOfflineEpic,
  purgeOfflineCacheEpic,
  removeVisibleOfflineSoundsEpic,
  processOfflineSoundQueueEpic,
)