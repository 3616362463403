import * as React from 'react'
import {Component, ReactNode} from 'react'
import {History} from 'history'
import {ConnectedRouter} from "connected-react-router"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import * as firebaseui from 'firebaseui'
import SwipeableViews from 'react-swipeable-views'
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  createStyles,
  CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Divider,
  Drawer, Fab,
  Grid,
  Hidden,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  SnackbarContent,
  SwipeableDrawer,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
  withWidth
} from '@material-ui/core'
import {SoundPlayerPanelContainer} from '../containers/SoundPlayerPanelContainer'
import {grey} from '@material-ui/core/colors'
import {SoundSourcePanelContainer} from '../containers/SoundSourcePanelContainer'
import {CustomToolbarContainer} from '../containers/CustomToolbarContainer'
import {Route, Switch} from 'react-router'
import {createSheetViewPath, Routes} from '../store/Routes'
import {SheetViewerContainer} from '../containers/SheetViewerContainer'
import {SheetEditorContainer} from '../containers/SheetEditorContainer'
import {WithWidth} from '@material-ui/core/withWidth'
import {ToolPanelContainer} from '../containers/ToolPanelContainer'
import {SoundMarkerListContainer} from '../containers/SoundMarkerListContainer'
import {SheetStructureListContainer} from '../containers/SheetStructureListContainer'
import SettingsIcon from '@material-ui/icons/Settings'
import NotesIcon from '@material-ui/icons/Notes'
import CloseIcon from '@material-ui/icons/Close'
import FavoriteIcon from '@material-ui/icons/Favorite'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import {UserProfile} from '../model/UserProfile'
import {SettingsListContainer} from '../containers/SettingsListContainer'
import {SeekSliderPanelContainer} from '../containers/SeekSliderPanelContainer'
import {TuningPanelContainer} from '../containers/TuningPanelContainer'
import {SoundInfoPanelContainer} from '../containers/SoundInfoPanelContainer'
import {SoundStreamingUrlPanelContainer} from '../containers/SoundStreamingUrlPanelContainer'
import {FilterPanelContainer} from '../containers/FilterPanelContainer'
import {AllSheetsSoundMarkerListContainer} from '../containers/AllSheetsSoundMarkerListContainer'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import AllInboxIcon from '@material-ui/icons/AllInbox'
import HomeIcon from '@material-ui/icons/Home'
import {Link} from 'react-router-dom'
import {SheetType} from '../model/SheetType'
import {SnackbarMessage, SnackbarMessageType} from '../model/SnackbarMessage'
import {getTopViewByIndex, getTopViewIndex, getTopViewLabel, TopView} from '../model/TopView'
import {helpSheetId, isBuiltInSheet} from '../util/sheet-ids'
import ReactToPrint, {PrintContextConsumer} from 'react-to-print'
import {FullScreen, useFullScreenHandle} from 'react-full-screen'
import {FullScreenHandle} from 'react-full-screen/dist'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'

export interface DataProps {
  history: History
  snackbarOpen: boolean
  audioIsReady: boolean,
  snackbarMessage?: SnackbarMessage
  showSoundSourcePanel: boolean
  showSeekSliderPanel: boolean
  showSoundPlayerPanel: boolean
  showTuningPanel: boolean
  showSoundMetadata: boolean
  showToolPanel: boolean
  showSoundStreamingUrlPanel: boolean
  topView: TopView
  persistentDrawerIsOpen: boolean
  swipeableDrawerIsOpen: boolean
  filterPanelIsOpen: boolean
  sheetIsLoading: boolean
  allSheetSoundMarkersAreLoading: boolean
  isOnline: boolean
  signInIsNecessary?: boolean
  sheetContentIsSet: boolean
  sheetId?: string
  sheetType?: SheetType
  isFullScreen: boolean
  useRealFullScreen: boolean
}

export interface DispatchProps {
  createNewSheet: () => void
  createHomeSheet: () => void
  closeSnackbar: () => void
  openDrawer: () => void
  closeDrawer: () => void
  openFilterPanel: () => void
  closeFilterPanel: () => void
  signedIn: (userProfile: UserProfile) => void
  signedOut: () => void
  enterTopView: (topView: TopView) => void
  initAudio: () => void
  setFullScreen: (isEnabled: boolean) => void
}

const styles = (theme: Theme) => createStyles({
  loadingBar: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: theme.zIndex.modal,
    width: '100%'
  },
  // The thing in the middle of the screen, spans complete width of screen
  mainArea: {
    flex: 1,
    display: 'flex',
    minHeight: 0,
  },
  mainAreaContentBox: {
    flex: 1,
    display: 'flex',
    minWidth: 0,
    minHeight: 0,
  },
  mainAreaContentCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'column',
  },
  miniPaper: {
    margin: '20%',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  centerButton: {
    margin: theme.spacing(2),
  },
  // The box in the main area, spans complete width and has two panels side by side
  mainAreaContentSideBySide: {
    display: 'flex',
    boxShadow: 'none',
    minWidth: 0,
    flex: 1,
  },
  // The sheet editor itself
  sheetEditor: {
    flex: 8,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  // The sheet viewer as single panel in the middle
  sheetViewerAsMainPanel: {
    backgroundColor: theme.palette.background.paper,
    flex: 1,
    boxShadow: 'none',
    boxSizing: 'border-box',
    minWidth: '200px',
    maxWidth: '980px',
    margin: '0 auto',
    // GitHub padding
    padding: '20px 45px',
  },
  // The container around the sheet viewer that acts as preview panel
  sheetViewerAsPreviewPanel: {
    flex: 8,
    padding: '20px 20px',
  },
  // The panel that contains all the sub panels on the bottom of the screen
  bottomPanel: {
    boxShadow: 'none',
    background: grey[100],
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  // The sub panels on the bottom of the screen
  bottomSubPanel: {
    margin: `${theme.spacing(1)}px 0`,
  },
  // The desktop drawer
  desktopDrawer: {
    height: '100%'
  },
  // The paper within the desktop drawer
  desktopDrawerPaper: {
    position: 'inherit',
    overflowY: 'hidden'
  },
  // The paper within the mobile drawer
  mobileDrawerPaper: {
    overflowY: 'hidden'
  },
  // Content of both desktop and mobile main drawer
  mainDrawerContent: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  // Content of right (desktop) filter drawer
  rightFilterDrawerContent: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  // Content of bottom (mobile) filter drawer
  bottomFilterDrawerContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  // Media queries
  '@media (max-width: 767px)': {
    sheetViewerAsMainPanel: {
      padding: '15px'
    },
    mainDrawerContent: {
      width: '260px',
    },
    rightFilterDrawerContent: {
      width: '260px',
    },
    bottomFilterDrawerContent: {},
  },
  snackbarContent: {
    flexWrap: 'nowrap',
  },
  snackbarCloseIcon: {
    fontSize: 20,
  },
  errorSnackbar: {
    backgroundColor: theme.palette.error.dark
  },
  infoSnackbar: {
    backgroundColor: theme.palette.primary.dark
  },
  bottomNavAction: {
    minWidth: 0,
  },
  navHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  navHeadingDivider: {
    height: '2px',
    backgroundColor: theme.palette.primary.main,
  },
  swipeableView: {
    display: 'flex',
    height: '100%'
  },
  failFastText: {
    color: theme.palette.action.disabled,
    textAlign: 'center',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
})

const HomeSheetLink = React.forwardRef((props: any, ref) => <Link to={Routes.Home} {...props} ref={ref}/>)
const HelpLink = React.forwardRef((props: any, ref) => <Link to={createSheetViewPath(helpSheetId)} {...props}
                                                             ref={ref}/>)

// This is a workaround to use the hook-only full-screen-react library in our class component.
function withFullScreenHook(WrappedComponent: any) {
  return function FullScreenComponent(props: any) {
    const fullScreenHandle = useFullScreenHandle()
    return <WrappedComponent {...props} fullScreenHandle={fullScreenHandle}/>
  }
}

interface WithFullScreenHandle {
  fullScreenHandle: FullScreenHandle
}

type RawAppProps = DataProps & DispatchProps & WithFullScreenHandle & WithWidth & WithStyles<typeof styles>

class RawApp extends Component<RawAppProps> {
  private unregisterAuthObserver?: firebase.Unsubscribe
  private printComponentRef: any

  public render() {
    const classes = this.props.classes
    return (
      <ConnectedRouter history={this.props.history}>
        <React.Fragment>
          <CssBaseline/>
          {this.props.useRealFullScreen &&
          <FullScreen handle={this.props.fullScreenHandle} onChange={this.onFullScreenChanged}>
            {this.props.isFullScreen && this.createSheetViewerContainer()}
          </FullScreen>
          }
          {!this.props.useRealFullScreen && this.props.isFullScreen && <React.Fragment>
            {this.mainAreaContentBox}
              <Fab color="primary" onClick={this.exitFullScreen} className={classes.fab}>
                  <FullscreenExitIcon/>
              </Fab>
          </React.Fragment>
          }
          {(this.props.useRealFullScreen || !this.props.isFullScreen) && this.everything}
        </React.Fragment>
      </ConnectedRouter>
    )
  }

  public componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if (user === null) {
        this.props.signedOut()
      } else {
        this.props.signedIn({
          name: user.displayName || user.email || user.uid
        })
      }
    })
  }

  public componentWillUnmount() {
    if (!this.unregisterAuthObserver) {
      return
    }
    this.unregisterAuthObserver()
  }

  private get everything() {
    const classes = this.props.classes
    return (
      <React.Fragment>
        {/* Mobile drawers */}
        <Hidden smUp={true} implementation="js">
          <SwipeableDrawer
            open={this.props.swipeableDrawerIsOpen}
            onOpen={this.props.openDrawer}
            onClose={this.props.closeDrawer}
            classes={{paper: classes.mobileDrawerPaper}}>
            {this.createMainDrawerContent()}
          </SwipeableDrawer>
          <SwipeableDrawer
            anchor="bottom"
            disableSwipeToOpen={true}
            open={this.props.filterPanelIsOpen}
            onOpen={this.props.openFilterPanel}
            onClose={this.props.closeFilterPanel}
          >
            <div className={classes.bottomFilterDrawerContent}>
              <FilterPanelContainer/>
            </div>
          </SwipeableDrawer>
        </Hidden>
        <AppBar position="static" color={this.props.isOnline ? 'primary' : 'default'}>
          <ReactToPrint content={() => this.printComponentRef}>
            <PrintContextConsumer>
              {({handlePrint}) => (
                <CustomToolbarContainer print={handlePrint} goFullScreen={this.goFullScreen}/>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </AppBar>
        {this.props.sheetIsLoading &&
        <LinearProgress color="secondary" variant="query" className={classes.loadingBar}/>
        }
        <div className={classes.mainArea}>
          {/* Desktop drawers */}
          <Hidden xsDown={true} implementation="js">
            {this.props.persistentDrawerIsOpen &&
            <Drawer variant="persistent" open={true} className={classes.desktopDrawer}
                    classes={{paper: classes.desktopDrawerPaper}}>
              {this.createMainDrawerContent()}
            </Drawer>
            }
            <Drawer
              anchor="right"
              open={this.props.filterPanelIsOpen}
              onClose={this.props.closeFilterPanel}
            >
              <div className={classes.rightFilterDrawerContent}>
                <FilterPanelContainer/>
              </div>
            </Drawer>
          </Hidden>
          {this.mainAreaContentBox}
        </div>
        {this.BottomPanel()}
        {this.MessageSnackbar()}
        {this.AudioInitDialog()}
      </React.Fragment>
    )
  }

  private get mainAreaContentBox() {
    const classes = this.props.classes
    return (
      <div className={classes.mainAreaContentBox}>
        <SwipeableViews
          axis="x"
          resistance={true}
          index={this.mainAreaContentIndex}
          onChangeIndex={this.handleMainAreaContentChange}
          containerStyle={{
            height: '100%'
          }}
          slideStyle={{
            overflow: 'hidden' // slides must implement scrolling
          }}
          style={{
            flex: 1
          }}
        >
          <div className={classes.swipeableView}>
            <this.AllSheetsSoundMarkerListMainAreaPaper/>
          </div>
          <div className={classes.swipeableView}>
            <Switch>
              <Route exact={true} path={Routes.SheetView} component={this.SheetViewerMainAreaPaper}/>
              <Route exact={true} path={Routes.SheetEdit} component={this.SheetEditorMainAreaPaper}/>
              <Route exact={true} path={Routes.CreateHomeSheet} component={this.CreateHomeSheetPaper}/>
              <Route exact={true} path="/index.html" component={this.SheetViewerMainAreaPaper}/>
              <Route exact={true} path="/" component={this.SheetViewerMainAreaPaper}/>
            </Switch>
          </div>
          <div className={classes.swipeableView}>
            <SoundMarkerListContainer/>
          </div>
          <div className={classes.swipeableView}>
            <SettingsListContainer/>
          </div>
        </SwipeableViews>
      </div>
    )
  }

  private get mainAreaContentIndex() {
    return getTopViewIndex(this.props.topView)
  }

  private handleMainAreaContentChange = (index: number) => {
    this.props.enterTopView(getTopViewByIndex(index))
  }

  private handleButtonNavChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.props.enterTopView(getTopViewByIndex(value))
  }

  private createMainDrawerContent = () => {
    const classes = this.props.classes
    const selectedColor = 'primary'
    return (
      <div className={classes.mainDrawerContent}>
        <Hidden smUp={true} implementation="css">
          <Typography variant="h6" align="center" className={classes.navHeading}>
            {getTopViewLabel(this.props.topView)}
          </Typography>
          <Divider className={classes.navHeadingDivider}/>
        </Hidden>
        {this.props.topView === TopView.Sheet && <>
            <Toolbar>
                <Grid container={true} direction="row" justify="space-around">
                    <Tooltip title="Home sheet">
                        <IconButton component={HomeSheetLink}
                                    color={this.props.sheetType === SheetType.HomeSheet ? selectedColor : 'default'}>
                            <HomeIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Jam Pad help">
                        <IconButton component={HelpLink}
                                    color={this.props.sheetType === SheetType.HelpSheet ? selectedColor : 'default'}>
                            <HelpOutlineIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Toolbar>
            <SheetStructureListContainer/>
        </>
        }
        <div style={{flexGrow: 1}}/>
        <BottomNavigation
          value={this.mainAreaContentIndex}
          onChange={this.handleButtonNavChange}
          showLabels={false}
        >
          <BottomNavigationAction className={classes.bottomNavAction} icon={<AllInboxIcon/>}/>
          <BottomNavigationAction className={classes.bottomNavAction} icon={<NotesIcon/>}/>
          <BottomNavigationAction className={classes.bottomNavAction} icon={<FavoriteIcon/>}/>
          <BottomNavigationAction className={classes.bottomNavAction} icon={<SettingsIcon/>}/>
        </BottomNavigation>
      </div>
    )
  }

  private goFullScreen = () => {
    if (this.props.useRealFullScreen) {
      if (!this.props.fullScreenHandle.active) {
        this.props.fullScreenHandle.enter()
      }
    } else {
      this.props.setFullScreen(true)
    }
  }

  private exitFullScreen = () => {
    if (this.props.useRealFullScreen) {
      if (this.props.fullScreenHandle.active) {
        this.props.fullScreenHandle.exit()
      }
    } else {
      this.props.setFullScreen(false)
    }
  }

  private onFullScreenChanged = (state: boolean, handle: FullScreenHandle) => {
    this.props.setFullScreen(state)
  }

  private enterSettingsTopView = () => {
    this.props.closeDrawer()
    this.props.enterTopView(TopView.Settings)
  }

  private enterQuickMarkersTopView = () => {
    this.props.closeDrawer()
    this.props.enterTopView(TopView.QuickMarkers)
  }

  private enterSheetTopView = () => {
    this.props.closeDrawer()
    this.props.enterTopView(TopView.Sheet)
  }

  private enterAllSheetsTopView = () => {
    this.props.closeDrawer()
    this.props.enterTopView(TopView.AllSheets)
  }

  private BottomPanel = () => {
    if (!this.props.showSoundMetadata && !this.props.showSoundStreamingUrlPanel && !this.props.showSoundMetadata &&
      !this.props.showSeekSliderPanel && !this.props.showSoundPlayerPanel && !this.props.showTuningPanel &&
      !this.props.showToolPanel) {
      return undefined
    }
    return (
      <Paper className={this.props.classes.bottomPanel}>
        {this.createBottomSubPanel(this.props.showSoundSourcePanel, <SoundSourcePanelContainer/>)}
        {this.createBottomSubPanel(this.props.showSoundStreamingUrlPanel, <SoundStreamingUrlPanelContainer/>)}
        {this.createBottomSubPanel(this.props.showSoundMetadata, <SoundInfoPanelContainer/>)}
        {this.createBottomSubPanel(this.props.showSeekSliderPanel, <SeekSliderPanelContainer/>)}
        {this.createBottomSubPanel(this.props.showSoundPlayerPanel, <SoundPlayerPanelContainer/>)}
        {this.createBottomSubPanel(this.props.showTuningPanel, <TuningPanelContainer/>)}
        {this.createBottomSubPanel(this.props.showToolPanel, <ToolPanelContainer/>)}
      </Paper>
    )
  }

  private createBottomSubPanel(condition: boolean, element: ReactNode) {
    if (!condition) {
      return undefined
    }
    return <div className={this.props.classes.bottomSubPanel}>{element}</div>
  }

  private SheetEditorMainAreaPaper = () => {
    return this.makeCentered(
      this.createSignInComponentIfNecessary(true) ||
      this.createLoadingSheetComponentIfNecessary()
    ) || (
      <Paper className={this.props.classes.mainAreaContentSideBySide}>
        {this.createSheetEditorMainPanelContent()}
      </Paper>
    )
  }

  private AllSheetsSoundMarkerListMainAreaPaper = () => {
    return this.makeCentered(
      this.createSignInComponentIfNecessary(false) ||
      this.createLoadingAllSheetSoundMarkersComponentIfNecessary()
    ) || <AllSheetsSoundMarkerListContainer/>
  }

  private CreateHomeSheetPaper = () => {
    const classes = this.props.classes
    return this.makeCentered(
      <Paper className={classes.miniPaper}>
        <Typography variant="body1">
          Click the following button to create your first sheet. It's going to be
          your <em>home sheet</em>. Next time you visit <a href="https://www.jampad.app">www.jampad.app</a>, your home
          sheet
          will be displayed instead of the welcome page.
        </Typography>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={this.props.createHomeSheet}
          className={classes.centerButton}
        >
          Create home sheet
        </Button>
      </Paper>
    )!
  }

  private SheetViewerMainAreaPaper = () => {
    if (this.props.sheetId && isBuiltInSheet(this.props.sheetId)) {
      return this.createSheetViewerContainer()
    }
    return this.makeCentered(
      this.createSignInComponentIfNecessary(true) ||
      this.createLoadingSheetComponentIfNecessary()
    ) || this.createSheetViewerContainer()
  }

  private createSheetViewerContainer() {
    return (
      <SheetViewerContainer className={this.props.classes.sheetViewerAsMainPanel}
                            ref={el => (this.printComponentRef = el)}/>
    )
  }

  private makeCentered(content: ReactNode | undefined) {
    if (!content) {
      return undefined
    }
    return (
      <div className={this.props.classes.mainAreaContentCentered}>
        {content}
      </div>
    )
  }

  private createLoadingAllSheetSoundMarkersComponentIfNecessary() {
    if (this.props.allSheetSoundMarkersAreLoading) {
      return <Typography variant="h4" className={this.props.classes.failFastText}>Loading all sheet
        markers...</Typography>
    }
    return undefined
  }

  private createLoadingSheetComponentIfNecessary() {
    if (this.props.sheetId && !this.props.sheetContentIsSet) {
      if (this.props.sheetIsLoading) {
        return <Typography variant="h4" className={this.props.classes.failFastText}>Loading sheet...</Typography>
      } else {
        return this.CreateSheetComponent()
      }
    }
    return undefined
  }

  // Sign-in button can occur only once because it's done via ID!
  private createSignInComponentIfNecessary(includeSignInButton: boolean) {
    const signInIsNecessary = this.props.signInIsNecessary
    if (signInIsNecessary === undefined) {
      return <Typography variant="h4" className={this.props.classes.failFastText}>Signing in...</Typography>
    }
    if (signInIsNecessary) {
      if (includeSignInButton) {
        return this.createSignInComponent()
      } else {
        return <Typography variant="h4" className={this.props.classes.failFastText}>Not signed in</Typography>
      }
    }
    return undefined
  }

  private createSignInComponent() {
    return (
      <StyledFirebaseAuth uiConfig={firebaseAuthUiConfig} firebaseAuth={firebase.auth()}/>
    )
  }

  private createSheetEditorMainPanelContent = () => {
    const classes = this.props.classes
    return (
      <>
        <div className={classes.sheetEditor}>
          <SheetEditorContainer/>
        </div>
        <Hidden smDown={true} implementation="js">
          <SheetViewerContainer className={classes.sheetViewerAsPreviewPanel}
                                ref={el => (this.printComponentRef = el)}/>
        </Hidden>
      </>
    )
  }

  private CreateSheetComponent = () => {
    const classes = this.props.classes
    return (
      <Paper className={classes.miniPaper}>
        <Typography variant="body1">Sheet <em>{this.props.sheetId}</em> doesn't
          exist</Typography>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={this.props.createNewSheet}
          className={classes.centerButton}
        >
          Create sheet
        </Button>
      </Paper>
    )
  }

  private AudioInitDialog = () => {
    return (
      <Dialog
        open={!this.props.audioIsReady}
        onClose={this.props.initAudio}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Allow audio</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            On iOS/Safari, Jam Pad needs your permission to play audio whenever you load the app.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.initAudio} color="primary" autoFocus>
            Allow playing audio
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private MessageSnackbar = () => {
    const snackbarMessage = this.props.snackbarMessage
    const classes = this.props.classes
    return (
      <Snackbar
        key={snackbarMessage ? snackbarMessage.text : ''}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.props.snackbarOpen}
        onClose={this.props.closeSnackbar}
        autoHideDuration={6000}
      >
        <SnackbarContent
          className={snackbarMessage && snackbarMessage.type === SnackbarMessageType.Error ? classes.errorSnackbar : classes.infoSnackbar}
          classes={{
            root: classes.snackbarContent
          }}
          message={snackbarMessage ? snackbarMessage.text : ''}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.props.closeSnackbar}
            >
              <CloseIcon className={classes.snackbarCloseIcon}/>
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }
}

const firebaseAuthUiConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  }
}

export const App = withWidth()(withStyles(styles)(withFullScreenHook(RawApp)))