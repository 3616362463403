import * as actions from './actions'
import {
  goToSoundMarker,
  handleSnackbarMessageChangeRequest,
  signedIn,
  snackbarMessageChanged,
  soundMarkerTargeted
} from './actions'
import {ActionType, isActionOf} from 'typesafe-actions'
import {combineEpics, Epic} from 'redux-observable'
import {ApplicationState} from '../ApplicationState'
import {buffer, debounceTime, distinctUntilChanged, filter, map, share, switchMap} from 'rxjs/operators'
import {currentlyVisibleSheetTitleSelector, requestedSheetIdSelector} from '../sheet/selectors'
import {concat, EMPTY, Observable, of} from 'rxjs'
import {createSheetViewPath} from '../Routes'
import {speak} from '../../util/text-to-speech'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import {getCurrentSpace, isLocalSpace} from '../../util/space-util'
import {initSheetData} from '../sheet/actions'
import {SnackbarMessageType} from '../../model/SnackbarMessage'
import {activeSoundMarkerSelector, currentPlaylistTypeSelector} from '../player/selectors'
import {SoundMarkerListType} from '../../model/SoundMarkerListType'
import {push} from "connected-react-router"
import {TopView} from '../../model/TopView'
import {PlaylistType} from '../../model/PlaylistType'
import * as desktopService from '../../services/DesktopService'
import * as sheetService from '../../services/SheetService'

const extendedActions = {
  ...actions,
  updateSheetInfos: initSheetData,
  push
}

type LayoutAction = ActionType<typeof extendedActions>
type LayoutEpic = Epic<LayoutAction, LayoutAction, ApplicationState>

const handleErrorEpic: LayoutEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.handleError)),
  map(action => {
    if (document.hidden && state$.value.settings.speechIsEnabled) {
      speak(action.payload)
    }
    return handleSnackbarMessageChangeRequest({type: SnackbarMessageType.Error, text: action.payload})
  })
)

const handleInfoEpic: LayoutEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.handleInfo)),
  map(action => handleSnackbarMessageChangeRequest({type: SnackbarMessageType.Info, text: action.payload}))
)

const goToActiveSoundMarkerEpic: LayoutEpic = (action$, state$) => {
  const buttonPresses$ = action$.pipe(
    filter(isActionOf(actions.goToActiveSoundMarker)),
    share()
  )
  const resets$ = buttonPresses$.pipe(debounceTime(250))
  return buttonPresses$.pipe(
    buffer(resets$),
    switchMap(buttonPresses => {
      const activeSoundMarker = activeSoundMarkerSelector(state$.value)
      if (!activeSoundMarker) {
        return EMPTY
      }
      return of(goToSoundMarker({
        soundMarker: activeSoundMarker,
        goToSource: buttonPresses.length > 1
      }))
    })
  )
}

const goToSoundMarkerEpic: LayoutEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.goToSoundMarker)),
  switchMap(action => {
    const sm = action.payload.soundMarker
    const targetedAction = soundMarkerTargeted({
      topView: sm.listInfo.type === SoundMarkerListType.Device
        ? TopView.QuickMarkers
        : currentPlaylistTypeSelector(state$.value) === PlaylistType.AllSheets && !action.payload.goToSource
          ? TopView.AllSheets
          : TopView.Sheet,
      soundMarker: sm
    })
    if (sm.listInfo.type === SoundMarkerListType.Sheet) {
      if (requestedSheetIdSelector(state$.value) !== sm.listInfo.sheetId) {
        return of(
          push(createSheetViewPath(sm.listInfo.sheetId)),
          targetedAction
        )
      }
    }
    return of(targetedAction)
  })
)

const showInstallPromptEpic: LayoutEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.showInstallPrompt)),
  filter(() => {
    desktopService.showInstallPrompt()
    return false
  })
)

const handleSnackbarMessageChangeRequestEpic: LayoutEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.handleSnackbarMessageChangeRequest)),
  // throttleTime(2000),
  map(action => snackbarMessageChanged(action.payload))
)

const signInEpic: LayoutEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.signIn)),
  switchMap(action => {
    if (isLocalSpace(getCurrentSpace())) {
      return EMPTY
    }
    sheetService.sync()
    return concat(
      of(signedIn(action.payload)),
      of(initSheetData())
    )
  })
)

const signOutEpic: LayoutEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.signOut)),
  filter(() => {
    firebase.auth().signOut()
    return false
  })
)

function sheetTitleChanged(state$: Observable<ApplicationState>): Observable<string | undefined> {
  return state$.pipe(
    map(currentlyVisibleSheetTitleSelector),
    distinctUntilChanged()
  )
}

const changeDocumentTitleWheneverSheetTitleChangesEpic: LayoutEpic = (action$, state$) => {
  return sheetTitleChanged(state$).pipe(
    switchMap(title => {
      document.title = title ? `Jam Pad - ${title}` : 'Jam Pad'
      return EMPTY
    })
  )
}

export const layoutEpic = combineEpics(
  changeDocumentTitleWheneverSheetTitleChangesEpic,
  handleErrorEpic,
  handleInfoEpic,
  showInstallPromptEpic,
  handleSnackbarMessageChangeRequestEpic,
  signInEpic,
  signOutEpic,
  goToActiveSoundMarkerEpic,
  goToSoundMarkerEpic,
)